"use client"

import {
	Activity,
	ArrowUpRight,
	ClipboardCheck,
	Clock,
	Truck,
	Users,
} from "lucide-react"
import { Area, AreaChart, CartesianGrid, Pie, PieChart, XAxis } from "recharts"

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { Button } from "@/components/ui/button"
import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "@/components/ui/card"
import type { ChartConfig } from "@/components/ui/chart"
import {
	ChartContainer,
	ChartTooltip,
	ChartTooltipContent,
} from "@/components/ui/chart"
import { Chip } from "@/components/ui/chip"
import {
	Page,
	PageBody,
	PageContent,
	PageGroup,
	PageHeader,
	PageInfo,
	PageTitle,
} from "@/components/ui/page"
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table"

function AreaChartComponent() {
	const chartData = [
		{ month: "January", scheduled: 186, unscheduled: 80 },
		{ month: "February", scheduled: 305, unscheduled: 200 },
		{ month: "March", scheduled: 237, unscheduled: 120 },
		{ month: "April", scheduled: 73, unscheduled: 190 },
		{ month: "May", scheduled: 209, unscheduled: 130 },
		{ month: "June", scheduled: 214, unscheduled: 140 },
	]

	const chartConfig = {
		scheduled: {
			label: "Scheduled",
			color: "hsl(var(--chart-1))",
		},
		unscheduled: {
			label: "Unscheduled",
			color: "hsl(var(--chart-2))",
		},
	} satisfies ChartConfig

	return (
		<Card className="col-span-2 flex flex-col">
			<CardHeader className="items-center pb-0">
				<CardTitle>Work Orders</CardTitle>
				<CardDescription>January - June 2024</CardDescription>
			</CardHeader>
			<CardContent className="flex-1 pb-0" />
			<ChartContainer
				config={chartConfig}
				className="col-span-2 min-h-[200px]"
			>
				<AreaChart
					accessibilityLayer
					data={chartData}
					margin={{
						left: 12,
						right: 12,
					}}
				>
					<CartesianGrid vertical={false} />
					<XAxis
						dataKey="month"
						tickLine={false}
						axisLine={false}
						tickMargin={8}
						tickFormatter={(value) => value.slice(0, 3)}
					/>
					<ChartTooltip
						cursor={false}
						content={<ChartTooltipContent indicator="dot" />}
					/>
					<Area
						dataKey="scheduled"
						type="natural"
						fill="var(--color-scheduled)"
						fillOpacity={0.4}
						stroke="var(--color-scehduled)"
						stackId="a"
					/>
					<Area
						dataKey="unscheduled"
						type="natural"
						fill="var(--color-unscheduled)"
						fillOpacity={0.4}
						stroke="var(--color-unscheduled)"
						stackId="a"
					/>
				</AreaChart>
			</ChartContainer>
		</Card>
	)
}

function PieChartComponent() {
	const chartData = [
		{ status: "In Service", vehicles: 171, fill: "var(--color-inService)" },
		{
			status: "Under Maintenance",
			vehicles: 18,
			fill: "var(--color-underMaintenance)",
		},
		{
			status: "Out of Service",
			vehicles: 11,
			fill: "var(--color-outOfService)",
		},
		{
			status: "Pending Inspection",
			vehicles: 22,
			fill: "var(--color-pendingInspection)",
		},
		{ status: "Other", vehicles: 5, fill: "var(--color-other)" },
	]

	const chartConfig = {
		vehicles: {
			label: "Vehicles",
		},
		inService: {
			label: "In Service",
			color: "hsl(var(--chart-1))",
		},
		underMaintenance: {
			label: "Under Maintenance",
			color: "hsl(var(--chart-2))",
		},
		outOfService: {
			label: "Out of Service",
			color: "hsl(var(--chart-3))",
		},
		pendingInspection: {
			label: "Pending Inspection",
			color: "hsl(var(--chart-4))",
		},
		other: {
			label: "Other",
			color: "hsl(var(--chart-5))",
		},
	} satisfies ChartConfig

	return (
		<Card className="col-span-2 flex flex-col">
			<CardHeader className="items-center pb-0">
				<CardTitle>Vehicle Statuses</CardTitle>
				<CardDescription>Last updated 32 mins ago</CardDescription>
			</CardHeader>
			<CardContent className="flex-1 pb-0">
				<ChartContainer
					config={chartConfig}
					className="mx-auto aspect-square max-h-[250px] pb-0 [&_.recharts-pie-label-text]:fill-foreground"
				>
					<PieChart>
						<ChartTooltip
							content={<ChartTooltipContent hideLabel />}
						/>
						<Pie
							data={chartData}
							dataKey="vehicles"
							label
							nameKey="status"
						/>
					</PieChart>
				</ChartContainer>
			</CardContent>
			<CardFooter className="flex-col gap-2 text-sm">
				<div className="leading-none text-foreground-weak">
					Showing all current vehicle statuses
				</div>
			</CardFooter>
		</Card>
	)
}

export default function DashboardPage() {
	return (
		<>
			<Page>
				<PageContent>
					<PageHeader>
						<PageInfo>
							<PageTitle>Dashboard</PageTitle>
							<PageGroup>Work</PageGroup>
						</PageInfo>
					</PageHeader>
					<PageBody className="flex flex-col gap-y-8 p-5">
						<div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4 lg:gap-8">
							<Card>
								<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
									<CardTitle className="text-sm font-medium">
										Total Vehicles
									</CardTitle>
									<Truck className="size-4 text-foreground-weaker" />
								</CardHeader>
								<CardContent>
									<div className="text-2xl font-bold">85</div>
									<p className="text-xs text-foreground-weaker">
										Active and inactive vehicles
									</p>
								</CardContent>
							</Card>
							<Card>
								<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
									<CardTitle className="text-sm font-medium">
										Scheduled Maintenance
									</CardTitle>
									<ClipboardCheck className="size-4 text-foreground-weaker" />
								</CardHeader>
								<CardContent>
									<div className="text-2xl font-bold">
										5 upcoming
									</div>
									<p className="text-xs text-foreground-weaker">
										Next service in 3 days
									</p>
								</CardContent>
							</Card>
							<Card>
								<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
									<CardTitle className="text-sm font-medium">
										Active Drivers
									</CardTitle>
									<Users className="size-4 text-foreground-weaker" />
								</CardHeader>
								<CardContent>
									<div className="text-2xl font-bold">
										+32
									</div>
									<p className="text-xs text-foreground-weaker">
										Currently on the road
									</p>
								</CardContent>
							</Card>
							<Card>
								<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
									<CardTitle className="text-sm font-medium">
										Shop Hours Tracked
									</CardTitle>
									<Clock className="size-4 text-foreground-weaker" />
								</CardHeader>
								<CardContent>
									<div className="text-2xl font-bold">
										340 hours
									</div>
									<p className="text-xs text-foreground-weaker">
										For maintenance work this month
									</p>
								</CardContent>
							</Card>
							<AreaChartComponent />
							<PieChartComponent />
						</div>
						<div className="grid gap-4 lg:grid-cols-2 lg:gap-8 xl:grid-cols-3">
							<Card className="xl:col-span-2">
								<CardHeader className="flex flex-row items-center">
									<div className="grid gap-2">
										<CardTitle>
											Maintenance History
										</CardTitle>
										<CardDescription>
											Recent maintenance activities across
											your fleet.
										</CardDescription>
									</div>
									<Button
										size="sm"
										className="ml-auto gap-1"
										variant="primary"
									>
										View All
										<ArrowUpRight className="ml-1 size-4" />
									</Button>
								</CardHeader>
								<CardContent className="@container">
									<Table>
										<TableHeader>
											<TableRow>
												<TableHead>Vehicle</TableHead>
												<TableHead className="hidden @xl:table-cell">
													Service Type
												</TableHead>
												<TableHead className="hidden @xl:table-cell">
													Status
												</TableHead>
												<TableHead className="hidden @xl:table-cell">
													Date
												</TableHead>
												<TableHead className="text-right">
													Cost
												</TableHead>
											</TableRow>
										</TableHeader>
										<TableBody>
											<TableRow>
												<TableCell>
													<div className="font-medium">
														Truck #12
													</div>
												</TableCell>
												<TableCell className="hidden @xl:table-cell">
													Oil Change
												</TableCell>
												<TableCell className="hidden @xl:table-cell">
													<Chip
														className="text-xs"
														variant="outline"
													>
														Completed
													</Chip>
												</TableCell>
												<TableCell className="hidden @xl:table-cell">
													2024-09-28
												</TableCell>
												<TableCell className="text-right">
													$1120.00
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell>
													<div className="font-medium">
														Truck #22
													</div>
												</TableCell>
												<TableCell className="hidden @xl:table-cell">
													Tire Rotation
												</TableCell>
												<TableCell className="hidden @xl:table-cell">
													<Chip
														className="text-xs"
														variant="outline"
													>
														In Progress
													</Chip>
												</TableCell>
												<TableCell className="hidden @xl:table-cell">
													2024-09-29
												</TableCell>
												<TableCell className="text-right">
													$810.00
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell>
													<div className="font-medium">
														Van #7
													</div>
												</TableCell>
												<TableCell className="hidden @xl:table-cell">
													Brake Replacement
												</TableCell>
												<TableCell className="hidden @xl:table-cell">
													<Chip
														className="text-xs"
														variant="outline"
													>
														Scheduled
													</Chip>
												</TableCell>
												<TableCell className="hidden @xl:table-cell">
													2024-10-01
												</TableCell>
												<TableCell className="text-right">
													$3200.00
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</CardContent>
							</Card>
							<Card>
								<CardHeader>
									<CardTitle>Recent Shop Hours</CardTitle>
								</CardHeader>
								<CardContent className="grid gap-8">
									<div className="flex items-center gap-4">
										<Avatar className="hidden size-9 sm:flex">
											<AvatarImage
												src="https://i.pravatar.cc/180?img=11"
												alt="Avatar"
											/>
											<AvatarFallback>JD</AvatarFallback>
										</Avatar>
										<div className="grid gap-1">
											<p className="text-sm font-medium leading-none">
												John Doe
											</p>
											<p className="text-sm text-foreground-weaker">
												Mechanic - 12 hours
											</p>
										</div>
									</div>
									<div className="flex items-center gap-4">
										<Avatar className="hidden size-9 sm:flex">
											<AvatarImage
												src="https://i.pravatar.cc/180?img=1"
												alt="Avatar"
											/>
											<AvatarFallback>AS</AvatarFallback>
										</Avatar>
										<div className="grid gap-1">
											<p className="text-sm font-medium leading-none">
												Alice Smith
											</p>
											<p className="text-sm text-foreground-weaker">
												Mechanic - 8 hours
											</p>
										</div>
									</div>
									<div className="flex items-center gap-4">
										<Avatar className="hidden size-9 sm:flex">
											<AvatarImage
												src="https://i.pravatar.cc/180?img=7"
												alt="Avatar"
											/>
											<AvatarFallback>BW</AvatarFallback>
										</Avatar>
										<div className="grid gap-1">
											<p className="text-sm font-medium leading-none">
												Bill Watson
											</p>
											<p className="text-sm text-foreground-weaker">
												Technician - 10 hours
											</p>
										</div>
									</div>
									<div className="flex items-center gap-4">
										<Avatar className="hidden size-9 sm:flex">
											<AvatarImage
												src="https://i.pravatar.cc/180?img=5"
												alt="Avatar"
											/>
											<AvatarFallback>LR</AvatarFallback>
										</Avatar>
										<div className="grid gap-1">
											<p className="text-sm font-medium leading-none">
												Laura Roberts
											</p>
											<p className="text-sm text-foreground-weaker">
												Technician - 9 hours
											</p>
										</div>
									</div>
									<div className="flex items-center gap-4">
										<Avatar className="hidden size-9 sm:flex">
											<AvatarImage
												src="https://i.pravatar.cc/180?img=12"
												alt="Avatar"
											/>
											<AvatarFallback>MW</AvatarFallback>
										</Avatar>
										<div className="grid gap-1">
											<p className="text-sm font-medium leading-none">
												Mark Wilson
											</p>
											<p className="text-sm text-foreground-weaker">
												Technician - 6 hours
											</p>
										</div>
									</div>
								</CardContent>
							</Card>

							<Card>
								<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
									<CardTitle className="text-sm font-medium">
										Fuel Usage
									</CardTitle>
									<Activity className="size-4 text-foreground-weaker" />
								</CardHeader>
								<CardContent>
									<div className="text-2xl font-bold">
										1,250 liters
									</div>
									<p className="text-xs text-foreground-weaker">
										Total fuel consumed this month
									</p>
								</CardContent>
							</Card>
							<Card>
								<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
									<CardTitle className="text-sm font-medium">
										Maintenance Costs
									</CardTitle>
									<ClipboardCheck className="size-4 text-foreground-weaker" />
								</CardHeader>
								<CardContent>
									<div className="text-2xl font-bold">
										$18,700
									</div>
									<p className="text-xs text-foreground-weaker">
										Spent on maintenance this year
									</p>
								</CardContent>
							</Card>
							<Card>
								<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
									<CardTitle className="text-sm font-medium">
										Incidents Reported
									</CardTitle>
									<Activity className="size-4 text-foreground-weaker" />
								</CardHeader>
								<CardContent>
									<div className="text-2xl font-bold">3</div>
									<p className="text-xs text-foreground-weaker">
										Accidents or issues reported this month
									</p>
								</CardContent>
							</Card>
							<Card>
								<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
									<CardTitle className="text-sm font-medium">
										Distance Traveled
									</CardTitle>
									<ArrowUpRight className="size-4 text-foreground-weaker" />
								</CardHeader>
								<CardContent>
									<div className="text-2xl font-bold">
										5,240 km
									</div>
									<p className="text-xs text-foreground-weaker">
										Total distance traveled this month
									</p>
								</CardContent>
							</Card>
						</div>
					</PageBody>
				</PageContent>
			</Page>
		</>
	)
}
